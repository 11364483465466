/* eslint-disable jsx-a11y/alt-text */
import React, { Component, Fragment } from 'react'
import { Container,Row, Col,Button} from 'react-bootstrap'
import webIcon from '../../asset/image/pdetails.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareCheck } from '@fortawesome/free-solid-svg-icons'
import ReactHtmlParser from "react-html-parser";
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';

class ProjectDetails extends Component {
  constructor(props){
    super();
    this.state={
      MyProjectID:props.id,
      img_two:"...",
      project_name:"...",
      project_description:"...",
      project_feature:"...",
      live_preview:"..."
    }
  }

  componentDidMount(){
    
    RestClient.GetRequest(AppUrl.ProjectDetails+this.state.MyProjectID).then(result=>{
      // console.log(result);
        this.setState({
          img_two:result[0]['img_two'],
          project_name:result[0]['project_name'],
          project_description:result[0]['project_description'],
          project_feature:result[0]['project_features'],
          live_preview:result[0]['live_preview'],
      });
        // this.setState({email:result[0]['email']})
        // this.setState({phone:result[0]['phone']})
    })
  }


  render() {
    return (
      <Fragment>
          <Container className="mt-5">
              <Row>
                  <Col lg={6} md={6} sm={12}>
                    <div className="about-thumb-wrap after-shape">
                      <img src={webIcon} />
                      {/* <img src={this.state.img_two} /> */}
                    </div>
                  </Col>
                  <Col lg={6} md={6} sm={12} className="mt-5">
                    <div className="project-details">
                      <h1 className="projectDetailsText">{ ReactHtmlParser(this.state.project_name) }</h1>
                      <p className="detailsName">
                        { ReactHtmlParser(this.state.project_description) }
                      </p>
                      <p className='cardSubTitle' style={{textAlign:"justify"}}><FontAwesomeIcon className='iconBullent' icon={faSquareCheck} />{ ReactHtmlParser(this.state.project_feature) }</p>
                      {/* <p className='cardSubTitle' style={{textAlign:"justify"}}><FontAwesomeIcon className='iconBullent' icon={faSquareCheck} /> HTML Training</p>
                      <p className='cardSubTitle' style={{textAlign:"justify"}}><FontAwesomeIcon className='iconBullent' icon={faSquareCheck} /> CSS Training</p>
                      <p className='cardSubTitle' style={{textAlign:"justify"}}><FontAwesomeIcon className='iconBullent' icon={faSquareCheck} /> PHP Training</p>
                      <p className='cardSubTitle' style={{textAlign:"justify"}}><FontAwesomeIcon className='iconBullent' icon={faSquareCheck} /> Laravel Training</p> */}
                      <Button variant="info" href={this.state.live_preview}>Live Preview</Button>
                    </div>
                  </Col>
              </Row>
          </Container>
      </Fragment>
    )
  }
}

export default ProjectDetails