import React, { Component, Fragment } from 'react'
import { Col, Container,Modal,Row, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideoSlash } from '@fortawesome/free-solid-svg-icons'
import 'video-react/dist/video-react.css';
import { Player, BigPlayButton } from 'video-react'
import ReactHtmlParser from "react-html-parser";
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import Loading from '../Loading/Loading';
import Zoom from 'react-reveal/Zoom';
 

class Video extends Component {
    constructor(){
        super();
        this.state={
            show:false,
            video_description:"...",
            video_url:"...",
            loading:true

        }
    }

    componentDidMount(){

      RestClient.GetRequest(AppUrl.HomeVideo).then(result=>{
        // console.log(result);
          this.setState({video_description:result[0]['video_description']})
          this.setState({video_url:result[0]['video_url'],loading:false})
      })
    }

    modalclose=()=>this.setState({show:false})
    modalOpen=()=>this.setState({show:true})

  render() {
    if(this.state.loading === true){
      return <Loading/>
    }else{
    return (
      <Fragment>
        <Container className='text-center'>
        <h1 className='serviceMainTitle'>Our Videos</h1>
          <div className='bottom'></div>
            <Row>
                <Col lg={6} md={6} sm={12} className="videText">
                    <Zoom top>
                      <p className='serviceDescription' style={{textAlign: "justify"}}>{ ReactHtmlParser(this.state.video_description) }</p>
                    </Zoom>
                </Col>
                <Col lg={6} md={6} sm={12} className="videoCard">
                <FontAwesomeIcon onClick={this.modalOpen} className='iconProject' icon={faVideoSlash} />
                </Col>
            </Row>
        </Container>



        <Modal size='lg' show={this.state.show} onHide={this.modalclose}>
        <Modal.Body>
        <Player src={this.state.video_url}>
        {/* <Player src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"> */}
          <BigPlayButton position="center" />
        </Player>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.modalclose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

        
      </Fragment>
    )
    }
  }
}

export default Video