import React, { Component,Fragment } from 'react'
import PageTop from '../components/PageTop/PageTop'
import TopNavigation from '../components/TopNavigation/TopNavigation'
import ContactSec from '../components/ContactSec/ContactSec'
import Footer from '../components/Footer/Footer'


export class ContactPage extends Component {
  componentDidMount(){
    window.scroll(0,0)
  }
  render() {
    return (
      <Fragment>
          <TopNavigation title="Contact Us"/>
          <PageTop pagetitle="Contact Us"/>
          <ContactSec/>
          <Footer/>
      </Fragment>
    )
  }
}

export default ContactPage