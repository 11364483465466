import React, { Component,Fragment } from 'react'
import PageTop from '../components/PageTop/PageTop'
import TopNavigation from '../components/TopNavigation/TopNavigation'
import AllProject from '../components/AllProject/AllProject'
import Footer from '../components/Footer/Footer'

class PortFolioPage extends Component {
  componentDidMount(){
    window.scroll(0,0)
  }
  render() {
    return (
        <Fragment>
        <TopNavigation title="Our Portfolio"/>
        <PageTop pagetitle="Our Portfolio"/>
        <AllProject/>
        <Footer/>
    </Fragment>
    )
  }
}

export default PortFolioPage