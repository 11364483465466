import React, { Component, Fragment  } from 'react'
import { Container, Row,  Col, Card, Button} from 'react-bootstrap'
import {Link} from "react-router-dom";
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import Loading from '../Loading/Loading';
import WentWrong from '../WentWrong/WentWrong';
import Zoom from 'react-reveal/Zoom';

class AllProject extends Component {
    constructor(){
        super();
        this.state={
          myData:[],
          loading:true,
          error:false
        }
      }
    
      componentDidMount(){
        RestClient.GetRequest(AppUrl.ProjectAll).then(result=>{
          if(result==null){
            this.setState({error:true,loading:false})
          }else{
          console.log(result);
            this.setState({myData:result,loading:false});
          }
        }).catch(error=>{
          this.setState({error:true})
        })
      }

  render() {

    if(this.state.loading === true){
      return <Loading/>
    }else if(this.state.loading === false){

    const Mylist = this.state.myData;
    const MyView = Mylist.map(Mylist=>{
      return <Col lg={4} md={6} sm={12}>
        <Zoom top>
          <Card className='projectCard'>
          <Card.Img variant="top" src={Mylist.img_one} width="250px" height="250px"/>
          <Card.Body>
              <Card.Title className='serviceName'>{Mylist.project_name}</Card.Title>
              <Card.Text className='serviceDescription'>
                {Mylist.project_description}
              </Card.Text>
              <Button variant="primary"><Link to={"/recent/"+Mylist.id} className="link-style">Visit More</Link></Button>
          </Card.Body>
          </Card>
        </Zoom>
      </Col>
    })

    return (
        <Fragment>
        <Container  className='text-center'>
        <h1 className='serviceMainTitle'>Recent Project</h1>
          <div className='bottom'></div>
            <Row>
                {MyView}
            </Row>
        </Container>
      </Fragment>
    )
  }
  else if(this.state.error === true){
    return <WentWrong/>
  }
}
}

export default AllProject