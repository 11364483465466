import React, { Component,Fragment } from 'react'
import { Col, Container,Row} from 'react-bootstrap'
import ReactHtmlParser from "react-html-parser";
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import Loading from '../Loading/Loading';
import WentWrong from '../WentWrong/WentWrong';
import RubberBand from 'react-reveal/RubberBand';

class TermsDescription extends Component {
  constructor(){
    super();
    this.state={
      termdesc:"Terms and Condition",
      loading:true,
      error:false
    }
  }

  componentDidMount(){

    RestClient.GetRequest(AppUrl.Information).then(result=>{
      if(result==null){
        this.setState({error:true,loading:false})
      }else{
      // console.log(result);
        this.setState({termdesc:result[0]['terms'],loading:false});
      }
    }).catch(error=>{
      this.setState({error:true})
    })
  }
  render() {
    if(this.state.loading === true){
      return <Loading/>
    }else if(this.state.loading === false){
    return (
      <Fragment>
          <Container className="mt-5">
            <Row>
                <Col lg={12} md={12} sm={12}>
                <RubberBand>
                  { ReactHtmlParser(this.state.termdesc) }
                </RubberBand>
                
                </Col>
            </Row>
        </Container>
      </Fragment>
    )
    }
    else if(this.state.error === true){
      return <WentWrong/>
    }
  }
}

export default TermsDescription