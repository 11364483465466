/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component, Fragment } from 'react'
import { Container,Row, Col} from 'react-bootstrap'
import {Link} from "react-router-dom";
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import Loading from '../Loading/Loading';
import WentWrong from '../WentWrong/WentWrong';
import Zoom from 'react-reveal/Zoom';


export class AllCourses extends Component {
    constructor(){
        super();
        this.state={
          myData:[],
          loading:true,
          error:false

        }
      }
    
      componentDidMount(){
        RestClient.GetRequest(AppUrl.CourseAll).then(result=>{
          if(result==null){
            this.setState({error:true,loading:false})
          }else{
          // console.log(result);
            this.setState({myData:result,loading:false});
          }//end error else
        }).catch(error=>{
          this.setState({error:true})
        })
      }

  render() {
    if(this.state.loading === true){
      return <Loading/>
    }else if(this.state.loading === false){

      

    console.log(this.state.myData);
    const Mylist = this.state.myData;
    const MyView = Mylist.map(Mylist=>{
      return <Col lg={6} md={12} sm={12}>
      <Row>
          <Col lg={6} md={6} sm={12} className='p-2'>
              <Zoom top>
                <img className='courseImage' src={Mylist.small_img} width="250px" height="200px"/>
              </Zoom>
              
          </Col>
          <Col lg={6} md={6} sm={12} className='p-2'>
          <Zoom top>
          <h5 className='serviceName' style={{textAlign: "justify"}}>{Mylist.short_title}</h5>
              <p className='serviceDescription' style={{textAlign: "justify"}}>{Mylist.short_description}</p>
              <Link to={'coursedetails/'+Mylist.id} className='courseViewMore' style={{float:"left"}}> View Details</Link>
        </Zoom>
              
          </Col>
          
      </Row>
  </Col>
    })
    return (
        <Fragment>
        <Container className='text-center'>
            <h1 className='serviceMainTitle'>My Courses</h1>
            <div className='bottom'></div>
            <Row>
                {MyView}
            </Row>
        </Container>
  </Fragment>
    )
  }//end else
  else if(this.state.error === true){
    return <WentWrong/>
  }
  }
}

export default AllCourses