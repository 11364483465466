import React, { Component, Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareCheck } from '@fortawesome/free-solid-svg-icons'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { faLaptop } from '@fortawesome/free-solid-svg-icons'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { Container, Row, Col, Button, Card } from 'react-bootstrap'
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor'
import Zoom from 'react-reveal/Zoom';

class Summary extends Component {
  render() {
    return (
      <Fragment>
        <Container fluid={true} className='summaryBanner p-0'>
            <div className='summaryBannerOverlay'>
                <Container className='text-center'>
                    <Row>
                        <Col lg={8} md={6} sm={12}>
                            <Row className='countSection' style={{marginTop: "20%"}}>
                                <Col>
                                <Zoom top>
                                    <FontAwesomeIcon className='iconProject' icon={faGlobe} />
                                    
                                        <h1 className='countNumber'>
                                        <CountUp start={0} end={35000}>
                                        {({ countUpRef, start }) => (
                                            <VisibilitySensor onChange={start} delayedCall>
                                            <span ref={countUpRef} />
                                            </VisibilitySensor>
                                        )}
                                        </CountUp>
                                        +
                                        </h1>
                                    </Zoom>
                                    
                                    <h4 className='countTitle'>Students Worldwide</h4>
                                    <hr style={{borderBottom:"3px solid white", width:"50px", margin:"auto"}}/>
                                </Col>
                                <Col>
                                    <Zoom top>
                                        <FontAwesomeIcon className='iconProject' icon={faLaptop} />
                                        <h1 className='countNumber'>
                                            <CountUp start={0} end={22}>
                                            {({ countUpRef, start }) => (
                                                <VisibilitySensor onChange={start} delayedCall>
                                                <span ref={countUpRef} />
                                                </VisibilitySensor>
                                            )}
                                            </CountUp>
                                        </h1>
                                    </Zoom>
                                    <h4 className='countTitle'>Courses Published</h4>
                                    <hr style={{borderBottom:"3px solid white", width:"50px", margin:"auto"}}/>
                                </Col>
                                <Col>
                                <Zoom top>
                                    <FontAwesomeIcon className='iconProject' icon={faStar} />
                                    <h1 className='countNumber'>
                                        <CountUp start={0} end={35000}>
                                            {({ countUpRef, start }) => (
                                                <VisibilitySensor onChange={start} delayedCall>
                                                <span ref={countUpRef} />
                                                </VisibilitySensor>
                                            )}
                                        </CountUp>
                                    </h1>
                                </Zoom>
                                
                                <h4 className='countTitle'>Students Reviews</h4>
                                <hr style={{borderBottom:"3px solid white", width:"50px", margin:"auto"}}/>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <Card className='workCard'>
                                <Card.Body>
                                    <Card.Title className='cardTitle'>What I have Achieved</Card.Title>
                                    <Card.Text>
                                    <p className='cardSubTitle' style={{textAlign:"justify"}}><FontAwesomeIcon className='iconBullent' icon={faSquareCheck} /> Requirement Gathering</p>
                                    <p className='cardSubTitle' style={{textAlign:"justify"}}><FontAwesomeIcon className='iconBullent' icon={faSquareCheck} /> System Analysis</p>
                                    <p className='cardSubTitle' style={{textAlign:"justify"}}><FontAwesomeIcon className='iconBullent' icon={faSquareCheck} /> Coding Testing</p>
                                    <p className='cardSubTitle' style={{textAlign:"justify"}}><FontAwesomeIcon className='iconBullent' icon={faSquareCheck} /> Implementation</p>
                                    </Card.Text>
                                    <Button variant="primary">Go somewhere</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Container>

      </Fragment>
    )
  }
}

export default Summary