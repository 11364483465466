/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from 'react'
import {Container,Row,Col } from 'react-bootstrap'
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import ReactHtmlParser from "react-html-parser";
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import WentWrong from '../WentWrong/WentWrong';

class Footer extends Component {
  constructor(){
    super();
    this.state={
      address:"...",
      email:"...",
      phone:"...",
      facebook:"...",
      youtube:"...",
      footer_credit:"...",
      loaderClass:"p-5 text-justify",
      mainDivClass:"d-none",
      error:false
    }
  }

  componentDidMount(){

    RestClient.GetRequest(AppUrl.FooterData).then(result=>{
      if(result==null){
        this.setState({error:true})
      }else{
      // console.log(result);
        this.setState({address:result[0]['address']})
        this.setState({email:result[0]['email']})
        this.setState({phone:result[0]['phone']})
        this.setState({facebook:result[0]['facebook']})
        this.setState({youtube:result[0]['youtube']})
        this.setState({twitter:result[0]['twitter']})
        this.setState({footer_credit:result[0]['footer_credit'],loaderClass:"d-none",mainDivClass:"p-5 text-justify"})
      } //end else for error
    }).catch(error=>{
      this.setState({error:true})
    })
    
  }

  render() {
    if(this.state.error === false){
    return (
      <Fragment>
          <Container fluid={true} className="footerSection">
              <Row>
                  <Col lg="3" md={6} sm={12} className="p-5 text-center">
                      <h2 className="footerName text-center">Follow Us</h2>
                      <div className="social-container">
                        <a className="facebook social" href={this.state.facebook} target="_blank">
                            <FontAwesomeIcon icon={faFacebook} size="2x"/>
                        </a>
                        <a className="youtube social" href={this.state.youtube} target="_blank">
                          <FontAwesomeIcon icon={faYoutube} size="2x"/>
                        </a>
                        <a className="twitter social" href={this.state.twitter} target="_blank">
                        <FontAwesomeIcon icon={faTwitter} size="2x"/>
                        </a>
                      </div>
                  </Col>
                  <Col lg="3" md={6} sm={12} className="p-5 text-justify">
                  <h2 className="footerName">Address</h2>
                  <p className="footerDescription"> { ReactHtmlParser(this.state.address) }<br></br>
                  <FontAwesomeIcon icon={faEnvelope}/> Email: { ReactHtmlParser(this.state.email) }<br></br>
                  <FontAwesomeIcon icon={faPhone}/> Phone: { ReactHtmlParser(this.state.phone) }
                  </p>
                  </Col>
                  <Col lg="3" md={6} sm={12} className="p-5 text-justify">
                  <h2 className="footerName">Information</h2>
                  <Link to="/about" className="footerLink">About Me</Link><br></br>
                  <Link to="/about" className="footerLink">Company Profile</Link><br></br>
                  <Link to="/contact" className="footerLink">Contact Us</Link><br></br>
                  </Col>
                  <Col lg="3" md={6} sm={12} className="p-5 text-justify">
                  <h2 className="footerName">Policy</h2>
                  <Link className="footerLink" to="/refund">Refund Policy</Link><br></br>
                  <Link className="footerLink" to="/terms">Terms And Condition</Link><br></br>
                  <Link className="footerLink" to="/privacy">Privacy Policy</Link><br></br>
                  </Col>
              </Row>
          </Container>
          <Container fluid="true" className="text-center copyrightSection">
            <a href="#" className="copyrightlink">{this.state.footer_credit}</a>

          </Container>
      </Fragment>
    )
    } //end if error
    else if(this.state.error === true){
      return <WentWrong/>
    }
  }
}

export default Footer