import React, { Component,Fragment } from 'react'
import PageTop from '../components/PageTop/PageTop'
import TopNavigation from '../components/TopNavigation/TopNavigation'
import TermsDescription from '../components/TermsDescription/TermsDescription'
import Footer from '../components/Footer/Footer'

class TermsPage extends Component {
  componentDidMount(){
    window.scroll(0,0)
  }
  render() {
    return (
      <Fragment>
          <TopNavigation title="Terms and Condition"/>
          <PageTop pagetitle="Terms and Condition"/>
          <TermsDescription/>
          <Footer/>
      </Fragment>
    )
  }
}

export default TermsPage