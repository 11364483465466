import React, { Component,Fragment } from 'react'
import {BrowserRouter as Router, Routes,Switch, Route, Link} from "react-router-dom";
import HomePage from '../pages/HomePage'
import AllServicePage from '../pages/AllServicePage'
import AllCoursePage from '../pages/AllCoursePage'
import PortfolioPage from '../pages/PortfolioPage'
import AboutPage from '../pages/AboutPage'
import ContactPage from '../pages/ContactPage'
import RefundPage from '../pages/RefundPage'
import TermsPage from '../pages/TermsPage'
import PrivacyPage from '../pages/PrivacyPage'
import ProjectDetailPage from '../pages/ProjectDetailPage'
import CourseDetailPage from '../pages/CourseDetailsPage'

class AppRouter extends Component {
  render() {
    return (
      <Fragment>
          <Switch>
            <Route exact path="/" component={HomePage}/>
            <Route exact path="/service" component={AllServicePage}/>
            <Route exact path="/course" component={AllCoursePage}/>
            <Route exact path="/portfolio" component={PortfolioPage}/>
            <Route exact path="/about" component={AboutPage}/>
            <Route exact path="/contact" component={ContactPage}/>
            <Route exact path="/refund" component={RefundPage}/>
            <Route exact path="/terms" component={TermsPage}/>
            <Route exact path="/privacy" component={PrivacyPage}/>
            <Route exact path="/recent/:projectID/" component={ProjectDetailPage}/>
            <Route exact path="/coursedetails/:courseID/" component={CourseDetailPage}/>
        </Switch>
      </Fragment>
    )
  }
}

export default AppRouter