import React, { Component,Fragment } from 'react'
import { Container,Row, Col} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ReactHtmlParser from "react-html-parser";
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import Loading from '../Loading/Loading';
import Zoom from 'react-reveal/Zoom';

class ContactSec extends Component {
    constructor(){
        super();
        this.state={
          address:"...",
          email:"...",
          phone:"...",
          loading:true
        }
      }
    
      componentDidMount(){
    
        RestClient.GetRequest(AppUrl.FooterData).then(result=>{
          // console.log(result);
            this.setState({address:result[0]['address'],
            loading:false
          })
            this.setState({email:result[0]['email']})
            this.setState({phone:result[0]['phone']})
            
        })
      }

      sendContact(){
          let name = document.getElementById("name").value;
          let email = document.getElementById("email").value;
          let message = document.getElementById("message").value;
          // alert(name+"/"+email+"/"+message);
          // RestClient.PostRequest(AppUrl.ContactSend,JSON.stringify);
          let jsonObject = {name:name,email:email,message:message}

          RestClient.PostRequest(AppUrl.ContactSend,JSON.stringify(jsonObject)).then(result=>{
            alert(result);
          }).catch(error=>{
            alert("Error");
          })

      }

  render() {
    if(this.state.loading == true){
      return <Loading/>
    }else{

    

    return (
      <Fragment>
          <Container className="mt-5">
              <Row>
                  <Col lg={6} md={6} sm={12}>
                      <h1 className="serviceName">Quick Connect</h1>
                      <Zoom left>
                      <Form>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Your Name</Form.Label>
                                <Form.Control id="name" type="text" placeholder="Enter your Name" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Your Email</Form.Label>
                                <Form.Control id="email" type="email" placeholder="Enter your Email" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Message</Form.Label>
                                <Form.Control id="message" as="textarea" rows={3}/>
                            </Form.Group>
                           
                            <Button variant="primary" onClick={this.sendContact}>
                                Submit
                            </Button>
                        </Form>
                        </Zoom>
                  </Col>
                  <Col lg={6} md={6} sm={12}>
                  <Zoom right>
                      <h1 className="serviceName">Discuss Now</h1>
                      <p className="serviceDescription">{ ReactHtmlParser(this.state.address) }<br></br>
                        <FontAwesomeIcon icon={faEnvelope}/> Email: { ReactHtmlParser(this.state.email) }<br></br>
                        <FontAwesomeIcon icon={faPhone}/> Phone: { ReactHtmlParser(this.state.phone) }
                      </p>
                      </Zoom>
                  </Col>
              </Row>
          </Container>
      </Fragment>
    )
  }
  }
}

export default ContactSec