/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/heading-has-content */
import React, { Component,Fragment } from 'react'
import { Container,Row, Col} from 'react-bootstrap'
import imageOne from '../../asset/image/page1.png'
import imageTwo from '../../asset/image/page2.png'
import imageThree from '../../asset/image/page3.png'
import bannerOne from '../../asset/image/19.png'
import bannertwo from '../../asset/image/20.png'
import bannerthree from '../../asset/image/21.png'
import Jump from 'react-reveal/Jump';
import Slide from 'react-reveal/Slide';
import Flip from 'react-reveal/Flip';

class Welcome extends Component {
  render() {
    return (
      <Fragment>
          <div className="intro-area-top">
            <Container>
                <Row>
                    <Col lg={12} md={12} sm={12}>
                        <div className="section-title text-center">
                            <div className="intro-area-inner">
                                    <h6 className="sub-title double-line">WELCOME</h6>
                                    <Jump>
                                        <h2 className="maintitle">
                                        Together<br></br>We Inspire Limitless Growth
                                        </h2>
                                    </Jump>
                                <Container className="text-center mt-5">
                                    <Row>
                                        <Col lg={4} md={6} sm={12}>
                                            <Slide top>
                                                <img alt='image-one' src={imageOne}/>
                                            </Slide>
                                            <Flip top>
                                                <h1 className="serviceName">Coding Made Simple</h1>
                                                <p className="serviceDescription">Lorem Ipsum Dolor Sit</p>
                                            </Flip>
                                            
                                        </Col>
                                        <Col lg={4} md={6} sm={12}>
                                            <Slide top>
                                                <img alt='image-two' src={imageTwo}/>
                                            </Slide>
                                            <Flip top>
                                                <h1 className="serviceName">Unleash Your Coding Potential</h1>
                                                <p className="serviceDescription">Lorem Ipsum Dolor Sit</p>
                                            </Flip>
                                        </Col>
                                        <Col lg={4} md={6} sm={12}>
                                            <Slide top>
                                                <img alt='image-three' src={imageThree}/>
                                            </Slide>
                                            <Flip top>
                                                <h1 className="serviceName">Forge Your Own Path</h1>
                                                <p className="serviceDescription">Lorem Ipsum Dolor Sit</p>
                                            </Flip>

                                        </Col>
                                    </Row>
                                </Container>
                                <Container>
                                <Row className="intro-footer bg-base text-center mt-5">
                                    <Col lg={4} md={6} sm={12}>
                                        <Row>
                                            <Col lg={6} md={6} sm={12}>
                                            
                                                <img className="sideImg" alt='banner-one' src={bannerOne}/>
                                            
                                                
                                                
                                            </Col>
                                            <Col lg={6} md={6} sm={12}>
                                                <h5 className="homeIntro" style={{textAlign:"justify"}}>Web Development</h5>
                                                <p className="serviceDescription" style={{textAlign:"justify"}}>Lorem Ipsum Dolor Sit</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={4} md={6} sm={12}>
                                        <Row>
                                            <Col lg={6} md={6} sm={12}>
                                                <img className="sideImg" alt='banner-one' src={bannertwo}/>
                                            </Col>
                                            <Col lg={6} md={6} sm={12}>
                                                <h5 className="homeIntro" style={{textAlign:"justify"}}>Ecommerce</h5>
                                                <p className="serviceDescription" style={{textAlign:"justify"}}>Lorem Ipsum Dolor Sit</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={4} md={6} sm={12}>
                                        <Row>
                                            <Col lg={6} md={6} sm={12}>
                                                <img className="sideImg" alt='banner-one' src={bannerthree}/>
                                            </Col>
                                            <Col lg={6} md={6} sm={12}>
                                                <h5 className="homeIntro" style={{textAlign:"justify"}}>Video Editing</h5>
                                                <p className="serviceDescription" style={{textAlign:"justify"}}>Lorem Ipsum Dolor Sit</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                </Container>
                                
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
          </div>
      </Fragment>
    )
  }
}

export default Welcome