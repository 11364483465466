import React, { Component, Fragment } from 'react'
import PageTop from '../components/PageTop/PageTop'

import { Container,Row, Col} from 'react-bootstrap'
import ProjectDetails from '../components/ProjectDetails/ProjectDetails'
import TopNavigation from "../components/TopNavigation/TopNavigation";
import Footer from '../components/Footer/Footer'

class ProjectDetailPage extends Component {

  constructor({match}){
      super();
      // console.log(match.params.projectName);
      this.state={
        projectPassedID:match.params.projectID,
        projectPassedName:match.params.projectName,
      }
  }

  componentDidMount(){
    window.scroll(0,0)
  }
  render() {
    return (
      <Fragment>
          <TopNavigation title="Project Details"/>
          <PageTop pagetitle={this.state.projectPassedName}/>
          <ProjectDetails id={this.state.projectPassedID}/>
          <Footer/>
      </Fragment>
    )
  }
}

export default ProjectDetailPage