/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component, Fragment } from 'react'
import { Container,Row, Col} from 'react-bootstrap'
import {Link} from "react-router-dom";
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import Loading from '../Loading/Loading';
import Zoom from 'react-reveal/Zoom';

class Courses extends Component {
    constructor(){
        super();
        this.state={
          myData:[],
          loading:true

        }
      }
    
      componentDidMount(){
        RestClient.GetRequest(AppUrl.CourseHome).then(result=>{
          // console.log(result);
            this.setState({myData:result,loading:false});
        })
      }
  render() {
    console.log(this.state.myData);
    const Mylist = this.state.myData;
    const MyView = Mylist.map(Mylist=>{
      return <Col lg={6} md={12} sm={12}>
      <Row>
          <Col lg={6} md={6} sm={12} className='p-2'>
                <Zoom top>
                    <img className='courseImage' src={Mylist.small_img} width="250px" height="200px"/>
                </Zoom>
          </Col>
          <Col lg={6} md={6} sm={12} className='p-2'>
          <Zoom top>
            <h5 className='serviceName' style={{textAlign: "justify"}}>{Mylist.short_title}</h5>
            <p className='serviceDescription' style={{textAlign: "justify"}}>{Mylist.short_description}</p>
            <Link to={'coursedetails/'+Mylist.id} className='courseViewMore' style={{float:"left"}}> View Details</Link>
        </Zoom>
          </Col>
          
      </Row>
  </Col>
    })

    if(this.state.loading === true){
        return <Loading/>
      }else{

    return (
      <Fragment>
            <Container className='text-center'>
                <h1 className='serviceMainTitle'>Our Courses</h1>
                <div className='bottom'></div>
                <Row>
                    {MyView}
                    {/* <Col lg={6} md={12} sm={12}>
                        <Row>
                            <Col lg={6} md={6} sm={12}>
                                <img className='courseImage' src=''/>
                                
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                                <h5 className='serviceName' style={{textAlign: "justify"}}>Laravel 8</h5>
                                <p className='serviceDescription' style={{textAlign: "justify"}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting</p>
                                <Link to='coursedetails' className='courseViewMore' style={{float:"left"}}> View Details</Link>
                            </Col>
                            <Col lg={6} md={6} sm={12} className='p-2'>
                                <img className='courseImage' src=''/>
                                
                            </Col>
                            <Col lg={6} md={6} sm={12} className='p-2'>
                                <h5 className='serviceName' style={{textAlign: "justify"}}>Laravel 8</h5>
                                <p className='serviceDescription' style={{textAlign: "justify"}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting</p>
                                <Link href='#' className='courseViewMore' style={{float:"left"}}> View Details</Link>
                            </Col>
                        </Row>
                    </Col>

                    <Col lg={6} md={12} sm={12}>
                        <Row>
                                <Col lg={6} md={6} sm={12} className='p-2'>
                                    <img className='courseImage' src=''/>
                                    
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <h5 className='serviceName' style={{textAlign: "justify"}}>Laravel 8</h5>
                                    <p className='serviceDescription' style={{textAlign: "justify"}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting</p>
                                    <Link href='#' className='courseViewMore' style={{float:"left"}}> View Details</Link>
                                </Col>
                                <Col lg={6} md={6} sm={12} className='p-2'>
                                    <img className='courseImage' src='https://img.freepik.com/premium-photo/searching-browsing-internet-data-information-networking-concept-two-businessman-hand-using-smart-phonemobile-tablet-office-xa_71455-1012.jpg?w=1060'/>
                                    
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <h5 className='serviceName' style={{textAlign: "justify"}}>Laravel 8</h5>
                                    <p className='serviceDescription' style={{textAlign: "justify"}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting</p>
                                    <Link href='#' className='courseViewMore' style={{float:"left"}}> View Details</Link>
                                </Col>
                            </Row>
                        
                    </Col> */}
                </Row>
            </Container>
      </Fragment>
    )
    }
  }
}

export default Courses