import React, { Component, Fragment } from 'react'
import {Container,Row,Col } from 'react-bootstrap'
import designIcon from '../../asset/image/design.png'
import ecommerceIcon from '../../asset/image/ecommerce.png'
import webIcon from '../../asset/image/web.png'
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import Loading from '../Loading/Loading';
import Zoom from 'react-reveal/Zoom';
import LightSpeed from 'react-reveal/LightSpeed';


class Services extends Component {

  constructor(){
    super();
    this.state={
      myData:[],
      loading:true
    }
  }

  componentDidMount(){
    RestClient.GetRequest(AppUrl.Services).then(result=>{
      // console.log(result);
        this.setState({myData:result,loading:false});
    })
  }

  render() {
    if(this.state.loading === true){
      return <Loading/>
    }else{
    console.log(this.state.myData);
    const Mylist = this.state.myData;
    const MyView = Mylist.map(Mylist=>{
      return <Col lg={4} md={4} sm={12}>
                <Zoom top>
                <div className='serviceCard text-center'>
                    <img alt='ecommerce' src={Mylist.service_logo} className="ecommerceIcom"/>
                    <h2 className='serviceName'>{Mylist.service_name}</h2>
                    <p className='serviceDescription'>{Mylist.service_description}</p>
                  </div>
              </Zoom>
        </Col>
    })
    return (
      <Fragment>
        <Container className='text-center'>
        <LightSpeed top>
          <h1 className='serviceMainTitle'>Our Services</h1>
        </LightSpeed>
          <div className='bottom'></div>
            <Row>
              {MyView}
                
                {/* <Col lg={4} md={4} sm={12}>
                <div className='serviceCard text-center'>
                      <img alt='design' src={designIcon} className="designIcom"/>
                      <h2 className='serviceName'>Design</h2>
                      <p className='serviceDescription'>I will Design and Develop your ecommerce Site</p>
                  </div>
                </Col>
                <Col lg={4} md={4} sm={12}>
                <div className='serviceCard text-center'>
                      <img alt='ecommerce' src={webIcon} className="webIcom"/>
                      <h2 className='serviceName'>Design</h2>
                      <p className='serviceDescription'>I will Design and Develop your ecommerce Site</p>
                  </div>
                </Col> */}
            </Row>
        </Container>

      </Fragment>
    )
              }
  }
}

export default Services
