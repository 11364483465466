/* eslint-disable jsx-a11y/alt-text */
import React, { Component, Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareCheck } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import { faClipboard } from '@fortawesome/free-solid-svg-icons'
import { faClone } from '@fortawesome/free-solid-svg-icons'
import { faTags } from '@fortawesome/free-solid-svg-icons'
import { Container, Row, Col, Button, Card } from 'react-bootstrap'
import {Link} from "react-router-dom";
import { Player, BigPlayButton } from 'video-react'
import ReactHtmlParser from "react-html-parser";
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';

class CourseDetails extends Component {
  constructor(props){
    super();
    this.state={
      courseID:props.id,
      short_title:"...",
      short_description:"...",
      small_img:"...",
      long_title:"...",
      long_description:"...",
      total_duration:"...",
      total_lecture:"...",
      total_student:"...",
      skill_all:"...",
      video_url:"...",
    }
  }

  componentDidMount(){
    
    RestClient.GetRequest(AppUrl.CourseDetails+this.state.courseID).then(result=>{
      console.log(result);
        this.setState({
          short_title:result[0]['short_title'],
          short_description:result[0]['short_description'],
          small_img:result[0]['small_img'],
          long_title:result[0]['long_title'],
          long_description:result[0]['long_description'],
          total_duration:result[0]['total_duration'],
          total_lecture:result[0]['total_lecture'],
          total_student:result[0]['total_student'],
          skill_all:result[0]['skill_all'],
          video_url:result[0]['video_url'],
      });
        // this.setState({email:result[0]['email']})
        // this.setState({phone:result[0]['phone']})
    })
  }
  render() {
    return (
      <Fragment>
          <Container className="mt-5">
              <Row>
                  <Col lg={8} md={6} sm={12}>
                  <h1 className="courseDetailsText">{ ReactHtmlParser(this.state.short_title) }</h1>
                    <img className="courseDetailsImg" src={this.state.small_img}/>
                    <p className='CourseallDescription'>{ ReactHtmlParser(this.state.long_description) }</p>
                  </Col>
                  <Col lg={4} md={6} sm={12}>
                      <div className="widget_feature">
                        <h4 className="widget-title text-center">Course Features</h4>
                        <hr/>
                          <ul>
                              <li><FontAwesomeIcon className='iconBullent' icon={faUser} /><span> Enrolled :</span> { ReactHtmlParser(this.state.total_student) } students</li>
                              <li><FontAwesomeIcon className='iconBullent' icon={faClock} /><span> Duration :</span> { ReactHtmlParser(this.state.total_duration) } hours</li>
                              <li><FontAwesomeIcon className='iconBullent' icon={faClipboard} /><span> Lectures :</span> 8</li>
                              <li><FontAwesomeIcon className='iconBullent' icon={faClone} /><span> Categories:</span> Technology</li>
                              <li><FontAwesomeIcon className='iconBullent' icon={faTags} /><span> Tags:</span> Android, JavaScript</li>
                              <li><FontAwesomeIcon className='iconBullent' icon={faSquareCheck} /><span> Instructor:</span> Md. Saiful Islam</li>
                          </ul>
                          <div className="price-wrap text-center">
                                  <Link className="btn btn-base btn-radius" to="#">Price:<span>$54.00</span></Link>
                                  <Button variant="warning"><span>ENROLL COURSE</span></Button>
                          </div>
                      </div>

                      <div style={{marginTop:"10px"}} >
                            <Player src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4">
                              <BigPlayButton position="center" />
                            </Player>
                      </div>

                      <div className="widget_feature" style={{marginTop:"10px"}}>
                        <h1 className="courseDetailsText text-center">Skill You need</h1><hr/>
                        <ul>
                          <li><FontAwesomeIcon className='iconBullent' icon={faSquareCheck} /> Metus Interdium metus</li>
                          <li><FontAwesomeIcon className='iconBullent' icon={faSquareCheck} /> Metus Interdium metus</li>
                          <li><FontAwesomeIcon className='iconBullent' icon={faSquareCheck} /> Metus Interdium metus</li>
                          <li><FontAwesomeIcon className='iconBullent' icon={faSquareCheck} /> Metus Interdium metus</li>
                        </ul>
                    </div>
                      
                  </Col>
              </Row>
          </Container>
      </Fragment>
    )
  }
}

export default CourseDetails