/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react'
import { Container, Row,Col } from 'react-bootstrap'
import { BarChart,ResponsiveContainer,Bar, XAxis,Tooltip } from 'recharts'
import ReactHtmlParser from "react-html-parser";
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import Loading from '../Loading/Loading';
import LightSpeed from 'react-reveal/LightSpeed';
import Zoom from 'react-reveal/Zoom';


class Analysis extends Component {

  constructor(){
    super();
    this.state={
      data:[],
      techdesc:"...",
      loading:true
      // data:[
      //   {Technology:'PHP',Projects:100},
      //   {Technology:'Mysqli',Projects:90},
      //   {Technology:'Laravel',Projects:95},
      //   {Technology:'React',Projects:85},
      //   {Technology:'Opencart',Projects:85},
      //   {Technology:'Vue Js',Projects:70},
      //   {Technology:'Django',Projects:60},
      //   {Technology:'JavaScript',Projects:100},
      // ]
    }
  }

  componentDidMount(){
    RestClient.GetRequest(AppUrl.ChartData).then(result=>{
      // console.log(result);
        this.setState({data:result});
    })
    RestClient.GetRequest(AppUrl.HomeTechDesc).then(result=>{
      // console.log(result);
        this.setState({techdesc:result[0]['tech_description'],loading:false});
    })
  }

  render() {

    if(this.state.loading === true){
      return <Loading/>
    }else{

    var blue = "#051b35"
    return (
      <Fragment>
        <Container className='text-center'>
        <LightSpeed top>
        <h1 className='serviceMainTitle'>TECHNOLOGY USED</h1>
        </LightSpeed>
        
          <div className='bottom'></div>
            <Row>
                <Col lg={6} md={12} sm={12}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart width={100} height={300} data={this.state.data}>
                    <XAxis dataKey="Techonology"/>
                    <Tooltip/>
                    <Bar dataKey="Projects" fill='blue'></Bar>
                  </BarChart>
                  </ResponsiveContainer>
                </Col>
                <Col>
                    <p className='serviceDescription' style={{textAlign: "justify"}}>
                      <Zoom top>
                        { ReactHtmlParser(this.state.techdesc) }
                      </Zoom>
                    </p>
                </Col>
            </Row>
        </Container>
      </Fragment>
    )
    }
  }
}

export default Analysis