import React, { Component, Fragment } from 'react'
import { Container, Row,  Col, Card, Button} from 'react-bootstrap'
import {Link} from "react-router-dom";
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import Loading from '../Loading/Loading';
import Zoom from 'react-reveal/Zoom';

class RecentProject extends Component {

  constructor(){
    super();
    this.state={
      myData:[],
      loading:true
    }
  }

  componentDidMount(){
    RestClient.GetRequest(AppUrl.ProjectHome).then(result=>{
      console.log(result);
        this.setState({myData:result,loading:false});
    })
  }

  



  render() {
    // console.log(this.state.myData);
    const Mylist = this.state.myData;
    const MyView = Mylist.map(Mylist=>{
      return <Col lg={4} md={6} sm={12}>
        <Zoom top>
          <Card className='projectCard'>
          <Card.Img variant="top" src={Mylist.img_one} width="250px" height="250px"/>
          <Card.Body>
              <Card.Title className='serviceName'>{Mylist.project_name}</Card.Title>
              <Card.Text className='serviceDescription'>
                {Mylist.project_description}
              </Card.Text>
              <Button variant="primary"><Link to={"/recent/"+Mylist.id} className="link-style">Visit More</Link></Button>
          </Card.Body>
          </Card>
        </Zoom>
      </Col>
    })

    if(this.state.loading === true){
      return <Loading/>
    }else{

    return (
      <Fragment>
        <Container  className='text-center'>
        <h1 className='serviceMainTitle'>Recent Project</h1>
          <div className='bottom'></div>
            <Row>
              {MyView}
                {/* <Col lg={4} md={6} sm={12}>
                <Card className='projectCard'>
                <Card.Img variant="top" src="https://img.freepik.com/free-vector/online-tutorials-concept_52683-37480.jpg?w=1060&t=st=1661517193~exp=1661517793~hmac=7e912c9d41b01c0ad605ac41c18a59221612ccce37013d99ff1aa05f804c717c" />
                <Card.Body>
                    <Card.Title className='serviceName'>DCC</Card.Title>
                    <Card.Text className='serviceDescription'>
                    Some quick example text to build on the card title and make up the
                    bulk of the card's content.
                    </Card.Text>
                    <Button variant="primary"><Link to="/recent" className="link-style">Visit More</Link></Button>
                </Card.Body>
                </Card>
                </Col> */}
                {/* <Col lg={4} md={6} sm={12}>
                <Card className='projectCard'>
                    <Card.Img variant="top" src="https://img.freepik.com/free-vector/online-tutorials-concept_52683-37481.jpg?w=1060&t=st=1661517653~exp=1661518253~hmac=a31d47c8d596580e88d733f47c4ae77f3d6a24583abd98651d65c6704fb19947" />
                    <Card.Body>
                        <Card.Title className='serviceName'>DCGPSC</Card.Title>
                        <Card.Text className='serviceDescription'>
                        Some quick example text to build on the card title and make up the
                        bulk of the card's content.
                        </Card.Text>
                        <Button variant="primary"><Link to="/recent" className="link-style">Visit More</Link></Button>
                    </Card.Body>
                    </Card>
                </Col>
                <Col lg={4} md={6} sm={12}>
                <Card className='projectCard'>
                    <Card.Img variant="top" src="https://img.freepik.com/free-vector/online-courses-tutorials_52683-37861.jpg?t=st=1661491248~exp=1661491848~hmac=27cba5c4a11894c7402f223dfa466a046d7da5fc83c903e4aa4bf72fd04364a7" />
                    <Card.Body>
                        <Card.Title className='serviceName'>ACPS</Card.Title>
                        <Card.Text className='serviceDescription'>
                        Some quick example text to build on the card title and make up the
                        bulk of the card's content.
                        </Card.Text>
                        <Button variant="primary"><Link to="/recent" className="link-style">Visit More</Link></Button>
                    </Card.Body>
                    </Card>
                </Col> */}
            </Row>
        </Container>
      </Fragment>
    )
    }
  }
}

export default RecentProject