import React from "react";
import AppRouter from './router/AppRouter'
import AboutPage from './pages/AboutPage'
import AllCoursePage from './pages/AllCoursePage'
import AllServicePage from './pages/AllServicePage'
import ContactPage from './pages/ContactPage'
import ContactSec from './components/ContactSec/ContactSec'
// import AllProject from './pages/PortfolioPage'
import HomePage from './pages/HomePage'
import PageTop from './components/PageTop/PageTop'
import TopNavigation from './components/TopNavigation/TopNavigation'
import AboutDescription from './components/AboutDescription/AboutDescription'
import {BrowserRouter} from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <AppRouter/>
    </BrowserRouter>
  );
}

export default App;
