import React, { Component,Fragment } from 'react'
import { Col,Container,Row } from 'react-bootstrap'
import ReactHtmlParser from "react-html-parser";
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import Loading from '../Loading/Loading';
import WentWrong from '../WentWrong/WentWrong';
import Zoom from 'react-reveal/Zoom';

class AboutDescription extends Component {
  constructor(){
    super();
    this.state={
      aboutdesc:"About Me",
      loading:true,
      error:false
    }
  }

  componentDidMount(){

    RestClient.GetRequest(AppUrl.Information).then(result=>{
      if(result==null){
        this.setState({error:true,loading:false})
      }else{
        this.setState({aboutdesc:result[0]['about'],loading:false});
      }
      // console.log(result);
        
    }).catch(error=>{
      this.setState({error:true})
    })
  }
  

  render() {
    if(this.state.loading === true){
      return <Loading/>
    }else if(this.state.loading === false){
    return (
      <Fragment>
          <Container className="mt-5">
              <Row>
                  <Col sm={12} lg={12} md={12}>
                    <Zoom top>
                        { ReactHtmlParser(this.state.aboutdesc) }
                    </Zoom>
                  </Col>
              </Row>

          </Container>
      </Fragment>
    )
    }//endif
    else if(this.state.error === true){
      return <WentWrong/>
    }

  }
}

export default AboutDescription