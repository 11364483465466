/* eslint-disable jsx-a11y/alt-text */
import React, { Component, Fragment } from 'react'
import {Navbar, Container,Nav,NavDropdown} from 'react-bootstrap'
import whiteLogo from '../../asset/image/logo_white.png'
import blackLogo from '../../asset/image/logo_black.png'
import "../../asset/css/custom.css"
import "../../asset/css/responsive.css"
import "../../asset/css/bootstrap.min.css"
import {NavLink} from "react-router-dom";


class TopNavigation extends Component {
    constructor(props){
        super();
        this.state={
            navBarTitle:'navTitle',
            navBarLogo:[whiteLogo],
            navVariant:"dark",
            navBarBack:"navBackground",
            navBarItem:"navItem",
            pageTitle:props.title
        }
    }

    onScroll=()=>{
      if(window.scrollY>100){
        this.setState({navBarTitle:'navTitleScroll',navBarLogo:[blackLogo],navBarBack:'navBackgroundScroll',navBarItem:'navItemScroll',navVariant:'light'});
      }else if(window.scrollY<100){
        this.setState({navBarTitle:'navTitle',navBarLogo:[whiteLogo],navBarBack:'navBackground',navBarItem:'navItem',navVariant:'dark'});
      }
    }

    componentDidMount(){
      window.addEventListener('scroll',this.onScroll);
    }


  render() {
    return (
      <Fragment>
        <title>{this.state.pageTitle}</title>
        <Navbar className={this.state.navBarBack} collapseOnSelect fixed="top" expand="lg" variant={this.state.navVariant}>
      <Container>
        <Navbar.Brand>
          <NavLink to="/" className={this.state.navBarTitle}>
            <img src={this.state.navBarLogo}/>
          </NavLink>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            
          </Nav>
          <Nav>
            <Nav.Link><NavLink exact activeStyle={{ color:'#ffd900'}} className={this.state.navBarTitle} style={{ textDecoration: 'none',fontSize:"20px",fontweight:"bold" }} to="/">HOME</NavLink></Nav.Link>
            <Nav.Link><NavLink exact activeStyle={{ color:'#ffd900'}} className={this.state.navBarTitle} style={{ textDecoration: 'none',fontSize:"20px",fontweight:"bold" }} to="/about">ABOUT</NavLink></Nav.Link>
            <Nav.Link><NavLink exact activeStyle={{ color:'#ffd900'}} className={this.state.navBarTitle} style={{ textDecoration: 'none',fontSize:"20px",fontweight:"bold" }} to="/service">SERVICE</NavLink></Nav.Link>
            <Nav.Link><NavLink exact activeStyle={{ color:'#ffd900'}} className={this.state.navBarTitle} style={{ textDecoration: 'none',fontSize:"20px",fontweight:"bold" }} to="/course">COURSE</NavLink></Nav.Link>
            <Nav.Link><NavLink exact activeStyle={{ color:'#ffd900'}} className={this.state.navBarTitle} style={{ textDecoration: 'none',fontSize:"20px",fontweight:"bold" }} to="/portfolio">PORTFOLIO</NavLink></Nav.Link>
            <Nav.Link><NavLink exact activeStyle={{ color:'#ffd900'}} className={this.state.navBarTitle} style={{ textDecoration: 'none',fontSize:"20px",fontweight:"bold" }} to="/contact">CONTACT US</NavLink></Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
      </Fragment>
    )
  }
}

export default TopNavigation