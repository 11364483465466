/* eslint-disable jsx-a11y/alt-text */
import React, { Component,Fragment } from 'react'
import { Col, Container,Row} from 'react-bootstrap'
import face from '../../asset/image/face.png'
import { init } from 'ityped'
import Zoom from 'react-reveal/Zoom';
import Flip from 'react-reveal/Flip';
import RubberBand from 'react-reveal/RubberBand';

class AboutMe extends Component {
    componentDidMount(){
        const myElement = document.querySelector('#myElement')
        init(myElement, { showCursor: false, strings: ['Use with React.js, Laravel,Mysql!', 'Online Content Creator!' ] })
      }
  render() {
    return (
      <Fragment>
          <Container className="text-center">
            <RubberBand>
              <h1 className='serviceMainTitle'>About Me</h1>
          </RubberBand>
          <div className='bottom'></div>
              <Row>
                  <Col lg={6} md={6} sm={12}>
                      <div className="aboutMeImage">
                        <Zoom top>
                          <img src={face} className="aboutImg"/>
                        </Zoom>
                      </div>
                  </Col>
                  <Col lg={6} md={6} sm={12}>
                      <div className="aboutMeBody">
                        <Flip left>
                        <h2 className="aboutMeDetails">HI There, I'm</h2>
                            <h2 className="aboutMeTitle">Md. Saiful Islam</h2>
                            <h3 className="aboutMeDetails">Work As <span id="myElement"></span></h3>
                        </Flip>
                      </div>
                  </Col>
              </Row>
          </Container>
      </Fragment>
    )
  }
}

export default AboutMe