import React, { Component, Fragment } from 'react'
import Analysis from "../components/Analysis/Analysis";
import Courses from "../components/Courses/Courses";
import Video from "../components/Video/Video";
import ClientReview from "../components/ClientReview/ClientReview";
import RecentProject from "../components/RecentProject/RecentProject";
import AboutMe from "../components/AboutMe/AboutMe";
import Services from "../components/Services/Services";
import Summary from "../components/Summary/Summary";
import Footer from "../components/Footer/Footer";
import TopBanner from "../components/TopBanner/TopBanner";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import Welcome from "../components/Welcome/Welcome";


class HomePage extends Component {
  componentDidMount(){
    window.scroll(0,0)
  }
  render() {
    return (
      <Fragment>
          <TopNavigation/>
            <TopBanner/>
            <Welcome/>
            <Services/>
            <Analysis/>
            <Summary/>
            <RecentProject/>
            <Courses/>
            <Video/>
            <ClientReview/>
            <AboutMe/>
            <Footer/>
      </Fragment>
    )
  }
}

export default HomePage