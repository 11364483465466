import React, { Component, Fragment } from 'react'
import { Container,Row, Col} from 'react-bootstrap'
import loaderIcon from '../../asset/image/loader.svg'

export class Loading extends Component {
  render() {
    return (
      <Fragment>
          <Container>
              <Row>
                  <Col>

                  <img alt='ecommerce' className="LoaderAnimation" src={loaderIcon} className="ecommerceIcom"/>

                  </Col>
              </Row>
          </Container>
      </Fragment>
    )
  }
}

export default Loading